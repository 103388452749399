<template>
  <!-- 问答首页 -->
  <div class="container">
    <!-- 搜索栏 -->
    <div class="search-bar">
      <img class="qa-icon" src="~@/assets/icon/znwd.png" />
      <span class="block label" @click="index">智能问答</span>

      <div class="navOne">
        <div :class="{ active: active == 1 }" @click="selectItem(1)">问答首页</div>
        <div :class="{ active: active == 2 }" @click="selectItem(2)">问题全部</div>
        <div :class="{ active: active == 3 }" @click="selectItem(3)">推荐专家</div>
      </div>

      <div class="block input">
        <input
          type="text"
          style="font-size: 15px"
          v-model="word"
          @keyup.enter="name"
          placeholder="搜索问题"
        />
        <span class="search-icon" @click="gotoSearchAsk"></span>
      </div>
      <a href="javaScript:void(0)" @click="gotoAsk" class="block btn">提问</a>
    </div>
    <!-- 主页面 -->
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      word: "",
      active: 1
    };
  },
  created() {
    if (this.$route.path == "/qaIndex") {
      this.active = 1;
    }
    if (this.$route.path == "/allQuestion") {
      this.active = 2;
    }
    if (this.$route.path == "/recommended") {
      this.active = 3;
    }
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.path != from.path) {
        if (to.path === "/qaIndex") {
          this.active = 1;
          return;
        }
        if (to.path === "/allQuestion") {
          this.active = 2;
          return;
        }
        if (to.path === "/recommended") {
          this.active = 3;
          return;
        }
      }
    }
  },
  methods: {
    selectItem(index) {
      this.active = index;
      if (this.active == 1) {
        this.$router.push("/qaIndex");
        return;
      }
      if (this.active == 2) {
        this.$router.push("/allQuestion");
        return;
      }
      if (this.active == 3) {
        this.$router.push("/recommended");
        return;
      }
    },
    gotoAsk() {
      sessionStorage.removeItem("askQuestion");//清空
      this.$router.push("/askQuestion");
    },
    index() {
      this.$router.push("/qaIndex");
    },
    gotoSearchAsk() {
      this.$router.push({
        path: "/searchAsk",
        query: {
          word: this.word
        }
      });

      //  this.$router.push({ name: "SearchAsk", params: { word: this.word } });
    },
    name() {
      this.$router.push({
        path: "/searchAsk",
        query: {
          word: this.word
        }
      });
      //  this.$router.push({ name: "SearchAsk", params: { word: this.word } });
    }
  }
};
</script>

<style scoped>
.container {
  background-color: #f7f7f7;
}
.search-bar {
  width: 80%;
  margin: 0 auto;
  padding: 20px 0;
  height: 45px;
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
}
.search-bar .block {
  font-size: 12px;
  margin-left: 40px;
}
.btn {
  text-align: center;
}
.search-bar .qa-icon {
  width: 43px;
}
.search-bar .label {
  cursor: pointer;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: rgba(0, 132, 255, 1);
  margin-left: 14px;
  font-weight: bold;
}
.search-bar .input {
  width: 210px;
  height: 34px;
  background: rgba(238, 238, 238, 1);
  border: 1px solid rgba(181, 193, 197, 1);
  border-radius: 17px;
  text-align: left;
  vertical-align: middle;
  /* margin-left: 61px; */
}
.search-bar .input input {
  height: 32px;
  border: none;
  width: 150px;
  background: rgba(238, 238, 238, 1);
  margin-left: 17px;
  vertical-align: middle;
  outline: none;
}
.search-bar .input .search-icon {
  cursor: pointer;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url(~@/assets/fdj2.png);
  background-size: 24px 24px;
  vertical-align: middle;
}
.search-bar a {
  width: 78px;
  height: 28px;
  background: rgba(0, 132, 255, 1);
  border-radius: 5px;
  color: #ffffff;
  text-decoration: none;
  line-height: 28px;
  vertical-align: middle;
  /* text-align: center; */
  margin-left: 26px;
}

.navOne {
  width: 400px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.navOne div {
  padding: 15px 0;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #3d3d3d;
  cursor: pointer;
  border-bottom: 3px transparent solid;
}

.navOne .active {
  border-color: #0084ff;
}
</style>
